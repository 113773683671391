import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import html2pdf from "html2pdf.js";
import html2canvas from "html2canvas";
import { toPng } from "html-to-image";
import moment from "moment";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Button,
  Stack,
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import UnitechHeader from "../image/certificate_header.png";
import UnitechFooter from "../image/certificate_footer.png";
import { fetchAddresses } from "../invoice/productDetails";
import { CSRFFormData } from "./editSrf/csrfForm";
import DownloadPdfLoaderModal from "../../utils/components/downloadPdfLoader";
import axiosWithToken from "../../utils/components/axiosTokenConfig";


export default function PrintCSRF() {
  const printComponentRef = React.useRef();
  const params = useParams();
  const [srfInstruments, setSrfInstruments] = React.useState("");
  const [serviceReqNumber, setServiceReqNumber] = React.useState("");
  const [receiptDate, setReceiptDate] = React.useState("");
  var [allInstruments, setInstruments] = React.useState([]);
  const [letterHead, setLetterHead] = React.useState(true);
  const [contact, setContact] = React.useState(null);
  const [dcDate, setDcDate] = React.useState("");
  const [entryDate, setEntryDate] = React.useState("");
  const [commitedDate, setCommitedDate] = React.useState("");
  const [dcNumber, setDcNumber] = React.useState("");
  const [clientID, setClientID] = React.useState("");
  const [companyData, setCompanyData] = React.useState({
    companyName: "",
    address: "",
  });
  const [address, setAddress] = React.useState("");
  const [companyName, setCompanyName] = React.useState("");
  const [clientArray, setClientArray] = React.useState([]);
  const [srfsData, setSRFsData] = React.useState([]);
  const [newAddressArray, setNewAddressArray] = React.useState([]);
  const [editAccess, setEditAccess] = React.useState(localStorage.getItem("editAccess")?.split(",").map(Number));

  const [pushArray, setPushArray] = React.useState([]);
  const [electronicSign, setElectronicSign] = React.useState(true);
  const [signImg, setSignImg] = React.useState(false);
  const [userId, setUserId] = React.useState({
    createdBy:"",
    approvedBy: "",
  });
  const [userImg, setUserImg] = React.useState({
    createdByImg:"",
    approvedByImg: "",
  });

  const [CSRFformState, setCSRFformState] = React.useState(
    CSRFFormData?.map((item) => ({ answer: "", details: "", ...item }))
  );
  const [quest16, setQuest16] = React.useState({
    reference_1: "",
    reference_2: "",
    reference_3: "",
    reference_4: "",
    laboratory_1: "",
    laboratory_2: "",
    laboratory_3: "",
    laboratory_4: "",
    engineer_1: "",
    engineer_2: "",
    engineer_3: "",
    engineer_4: "",
  });
  const [quest17, setQuest17] = React.useState({
    drop_pickup: false,
    pickup_by_external_provider: false,
    jrpm_send_by_courier: false,
  });
  const [quest15, setQuest15] = React.useState({});
  const [downloadPdfloading, setDownloadPdfloading] = React.useState(false);

  const getCERFForm = async () => {
    await axiosWithToken
      .get(BASE_URL + "csrfForm")
      .then((res) => {
        const data = res.data?.find((item) => item?.srfId == params.id);
        if (data) {
          const updatedCSRFformState = CSRFFormData?.map((item, index) => {
            const fetchedQuestion = data[`quession${index + 1}`];
            const { answer, details } = fetchedQuestion
              ? JSON.parse(fetchedQuestion)
              : { answer: "", details: "" };
            return {
              ...item,
              answer,
              details,
            };
          });
          setCSRFformState(updatedCSRFformState);

          setQuest15(data?.quession15 ? JSON.parse(data?.quession15) : "");

          const question16 = data?.quession16
            ? JSON.parse(data?.quession16)
            : {};
          setQuest16({
            reference_1: question16.reference_1 || "",
            reference_2: question16.reference_2 || "",
            reference_3: question16.reference_3 || "",
            reference_4: question16.reference_4 || "",
            laboratory_1: question16.laboratory_1 || "",
            laboratory_2: question16.laboratory_2 || "",
            laboratory_3: question16.laboratory_3 || "",
            laboratory_4: question16.laboratory_4 || "",
            engineer_1: question16.engineer_1 || "",
            engineer_2: question16.engineer_2 || "",
            engineer_3: question16.engineer_3 || "",
            engineer_4: question16.engineer_4 || "",
          });

          const question17 = data?.quession17
            ? JSON.parse(data?.quession17)
            : {};
          setQuest17({
            drop_pickup: question17.drop_pickup || false,
            pickup_by_external_provider:
              question17.pickup_by_external_provider || false,
            jrpm_send_by_courier: question17.jrpm_send_by_courier || false,
          });
        }
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
          toast.error("Something Went Wrong!");
        }
      });
  };

  function fetchSrfDetails() {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `srfs?_where=(id,eq,${params.id})`)
      .then((res) => {
        setServiceReqNumber(res.data[0]?.serviceReqNumber);
        setDcDate(res.data[0]?.dcDate);
        setReceiptDate(res.data[0]?.receiptDate);
        setEntryDate(res.data[0]?.entryDate);
        setCommitedDate(res.data[0]?.committedDeliveryDate);
        setDcNumber(res.data[0]?.dcNumber);
        setClientID(res.data[0]?.clientId);
        setAddress(res.data[0]?.address);
        setSRFsData(res.data[0]);
       
      const createdByUserId = res.data[0]?.createdby;
      const approvedByUserId = res.data[0]?.approvedby;
      Promise.all([
        createdByUserId
          ? axiosWithToken.get(BASE_URL + `users/${createdByUserId}`)
          : Promise.resolve(null),
        approvedByUserId
          ? axiosWithToken.get(BASE_URL + `users/${approvedByUserId}`)
          : Promise.resolve(null),
      ])
        .then(([createdByRes, approvedByRes]) => {
          const createdByUserData = createdByRes?.data[0];
          const approvedByUserData = approvedByRes?.data[0];
          const createdByName = createdByUserData?.userName || "";
          const approvedByName = approvedByUserData?.userName || "";
          const createdByImg = createdByUserData?.usersign || "";
          const approvedByImg = approvedByUserData?.usersign || "";
          setUserId((prevState) => ({
            ...prevState,
            createdBy: createdByName,
            approvedBy: approvedByName,
          }));
          setUserImg((prevState) => ({
            ...prevState,
            createdByImg: createdByImg,
            approvedByImg: approvedByImg,
          }));
        })
        .catch((err) => console.error("Error fetching user data:", err));
    })
      
      .catch((err) => {
        console.log("error:", err);
      });
  }

  const getClientId = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `clients?_where=(id,eq,${clientID})`)
      .then((res) => {
        setCompanyData({
          companyName: res.data[0]?.companyName,
          address: res.data[0]?.address,
        });
        setClientArray(res.data);
        setCompanyName(res.data[0]?.companyName);
        setContact(
          res.data[0]?.contact ? JSON.parse(res.data[0]?.contact)[0] : null
        );
      })
      .catch((error) => {
        console.error("Something Went Wrong!");
      });
  };

  const srfInstrumentsList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `srfInstruments?_where=(srfsId,eq,${params.id})`)
      .then((res) => {
        setSrfInstruments(res.data);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
          toast.error("Something Went Wrong!");
        }
      });
  };

  const instrumentsList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `instruments?_where=(status,eq,1)`)
      .then((res) => {
        setInstruments(res.data);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
          toast.error("Something Went Wrong!");
        }
      });
  };

  const initiateRanges = () => {
    let rows = [];
    for (let i = 0; i < srfInstruments.length; i++) {
      rows.push([srfInstruments[i].id, srfInstruments[i].instrumentId]);
    }

    for (let i = 0; i < rows.length; i++) {
      for (let j = 0; j < allInstruments.length; j++) {
        if (allInstruments[j].id === rows[i][1]) {
          rows[i][1] = rows[i][0] + ", " + allInstruments[j].instrumentName;
          // delete rows[i][0];
        }
      }
    }
    // console.log(rows);
  };

  const generatePushArray = (srfInstruments, allInstruments) => {
    const newPushArray = [];

    for (let i = 0; i < srfInstruments.length; i++) {
      newPushArray.push({
        instrumentId: srfInstruments[i].requestedDucName ? srfInstruments[i].requestedDucName : allInstruments.find(
          (element) => element.id === srfInstruments[i].instrumentId
        )?.instrumentName,
        ranges: srfInstruments[i].ranges,
        lc: srfInstruments[i].lc,
        make: srfInstruments[i].make,
        conOfReceipt: srfInstruments[i].conOfReceipt,
        calFrequency: srfInstruments[i].calFrequency,
        accuracy: srfInstruments[i].accuracy,
        model: srfInstruments[i].model,
        serialNumber: srfInstruments[i].serialNumber,
        DUCID: srfInstruments[i].DUCID,
        calPoint: srfInstruments[i].calPoint,
        ConOfDuc: srfInstruments[i].ConOfDuc,
        calFrequency: srfInstruments[i].calFrequency,
      });
    }

    return newPushArray;
  };

  useEffect(() => {
    setPushArray(generatePushArray(srfInstruments, allInstruments));
  }, [srfInstruments, allInstruments]);

  useEffect(() => {
    srfInstrumentsList();
    instrumentsList();
    getCERFForm();
    fetchSrfDetails();
  }, []);

  useEffect(() => {
    getClientId();
  }, [clientID]);

  useEffect(() => {
    initiateRanges();
  }, [srfInstruments, allInstruments]);

  useEffect(async () => {
    let splitAddress = address?.split(",");
    let newAddressArray = await fetchAddresses(splitAddress?.[0], "0");
    setNewAddressArray(newAddressArray);
  }, [address]);

  const generateLetterHeadHeader = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechHeader} style="width:100%; height:150px;"/>
    </div>`;
    let div = document.createElement("div");
    div.innerHTML = htmlString;
    document.body.appendChild(div);
    let canvas = await html2canvas(div, { scale: 1.3 });
    const img = new Image();
    img.src = canvas.toDataURL("image/jpeg", 0.90);
    document.body.removeChild(div);
    return img;
  };

  const generateLetterHeadFooter = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechFooter} style="width:100%; height:150px;"/>
    </div>`;
    let div = document.createElement("div");
    div.innerHTML = htmlString;
    document.body.appendChild(div);
    let canvas = await html2canvas(div, { scale: 1.3 });
    const img = new Image();
    img.src = canvas.toDataURL("image/jpeg", 0.90);
    document.body.removeChild(div);
    return img;
  };

  const generateDynamicHeader = async (pageNo, totalPages) => {
    const formattedCurrentPage = pageNo.toString().padStart(2, "0");
    const formattedTotalPages = totalPages.toString().padStart(2, "0");
    const pageRange = `${formattedCurrentPage}/${formattedTotalPages}`;
    let htmlString = `    
    <div id="responsivepdfheader">
      <table  style="width:100%;">
        <tr>
          <td style="border:none;padding:5px;font-family: Calibri, sans-serif;height:60px;text-align:right">
            <b style="font-size:20px;">Page Number : </b>
            <span style="font-size:19px;">${pageRange}</span>
          </td>
        </tr>
      </table>
    </div>
    `;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 1, scale: 1 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

  const generateDynamicFooter = async () => {
    let htmlString = `
    <div style="padding:30px;">
    <table style="width:100%;">
        <tr>
        <td style="disply:flex;flex-direction:column;width:50%;text-align: left;font-family: Calibri, sans-serif;line-height:30px;">
        <div style="border-bottom:1px solid black;padding:2px 15px;">
        <b style="font-size:18px;">Prepared & Issued By</b>
        </div>
         <b style="font-size:17px;padding:0px 15px;">${userId?.createdBy}</b>
         ${signImg ? 
          `<div style="padding:5px 15px;height:50px;">
            ${userImg?.createdByImg ? `<img src="${userImg?.createdByImg}" style="width:30%; height:100%;border:none;"/>` : ''}
           </div>`
          : ''}  
         </div>
        </td>
         <td style="disply:flex;flex-direction:column;width:50%;text-align: right;font-family: Calibri, sans-serif;line-height:30px;">
         <div style="border-bottom:1px solid black;padding:2px 15px;">
         <b style="font-size:18px;">Approved By</b>
         </div> 
          <b style="font-size:17px;padding:0px 15px;">${userId?.approvedBy}</b>
          ${signImg ? 
            `<div style="padding:5px 15px;height:50px;">
              ${userImg?.approvedByImg ? `<img src="${userImg?.approvedByImg}" style="width:30%; height:100%;border:none;"/>` : ''}
             </div>`
            : ''}          
        </td>
        </tr>
    </table>
</div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 1, scale: 1 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

  const generatePDF = async () => {
    setDownloadPdfloading(true);
    try {
      const element = printComponentRef.current;
      let pdf = await html2pdf()
        .from(element)
        .set({
          margin: [95, 1, 105, 1],
          filename: `srf_${params.id}.pdf`,
          pagebreak: { after: "section", mode: ["css", "legacy"] },
          html2canvas: {
            dpi: 192,
            scale: 4,
            letterRendering: true,
            useCORS: true,
          },
          jsPDF: {
            orientation: "portrait",
            unit: "pt",
            format: "a4",
            compress: true,
          },
        })
        .toPdf()
        .get("pdf");

      const totalPages = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(9);
        pdf.setTextColor(0, 0, 0);
        pdf.setFont("Courier");

        // border add for contnet
        const borderWidth = 0.6;
        const leftMargin = 15.5;
        const borderX = leftMargin;
        const borderY = 90;
        const rightMargin = 595 - leftMargin * 0.95;
        pdf.setLineWidth(borderWidth);
        pdf.rect(borderX, borderY, rightMargin - leftMargin, 650);

        let image = await generateDynamicHeader(i, totalPages);
        pdf.addImage(image, 0, 58, 595, 50);

        image = await generateDynamicFooter();
        pdf.addImage(image, 0, 740, 595, 75);

        if (letterHead) {
          let image = await generateLetterHeadHeader();
          pdf.addImage(image, 0, 0, 595, 75);

            // image = await generateLetterHeadFooter();
            // pdf.addImage(image, 0, 772, 595, 70);
          }

        if (i === totalPages) {
          const text =
            "This SRF is electronically signed and does not required physical signature";
          const textWidth =
            (pdf.getStringUnitWidth(text) * pdf.internal.getFontSize()) /
            pdf.internal.scaleFactor;
          const pageWidth = pdf.internal.pageSize.getWidth();
          const x = (pageWidth - textWidth) / 2;
          {
            electronicSign && pdf.text(text, x, 835);
          }
        }
      }
      const blob = pdf.output("blob");
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${companyName}_${serviceReqNumber}.pdf`;
      a.click();
      // pdf.save();
      setDownloadPdfloading(false);
    } catch (error) {
      console.error("Error generating PDF:", error);
      setDownloadPdfloading(false);
    }
  };

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Grid container spacing={2} align="left" style={{ display: "flex" }}>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="srf-viewsrf_letterhead"
            options={[
              { id: 1, label: "With Letter Head" },
              { id: 2, label: "Without Letter Head" },
            ]}
            renderInput={(params) => (
              <TextField {...params} label="letterHead" />
            )}
            onInputChange={(event, newInputValue) => {
              if (
                newInputValue === "With Letter Head" ||
                newInputValue === ""
              ) {
                setLetterHead(true);
              } else if (newInputValue === "Without Letter Head") {
                setLetterHead(false);
              }
              // newInputValue == "Approve" ? setEnableDisable() : setEnableDisable(4+':'+result.toString());
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Autocomplete
                    size="small"
                    id="certificate-certificateviewelectronicsign"
                    options={[
                      { id: 1, label: "With Electronically Signed" },
                      { id: 3, label: "Signature Image" },
                      { id: 2, label: "Without  Electronically Signed" },
                    ]}
                    renderInput={(params) => (
                      <TextField {...params} label="Electronically Signed" />
                    )}
                    onInputChange={(event, newInputValue) => {
                      if (
                        newInputValue === "With Electronically Signed" ||
                        newInputValue === ""
                      ) {
                        setElectronicSign(true);
                      } else if (
                        newInputValue === "Without  Electronically Signed"
                      ) {
                        setElectronicSign(false);
                        setSignImg(false);
                      }
                      else if (
                        newInputValue === "Signature Image"
                      ) {
                        setElectronicSign(false);
                        setSignImg(true);
                      }
                    }}
                  />
                </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            size="small"
            sx={{ ml: 3 }}
            disabled={!(editAccess?.includes(3) || editAccess?.includes(0))}
            onClick={() => {
              generatePDF();
            }}
          >
            Download PDF
          </Button>
        </Grid>
      </Grid>
      <br />
      <div className="srf" ref={printComponentRef}>
        <div
          style={{
            width: "95%",
            margin: "0 auto",
            // border: "1px solid black",
            lineHeight: "16px",
          }}
        >
          <div className="row" style={{ lineHeight: "16px" }}>
            <div className="col-3"></div>
            <div className="col-6 mt-2">
              <h4 className="font-bold text-center">
                Calibration Work Order (SRF)
              </h4>
            </div>
          </div>
        </div>
        <div
          className="srfFulfilled"
          style={{
            width: "95%",
            margin: "0 auto",
            // border: "1px solid black",
            borderTop: "none",
            lineHeight: "16px",
            textAlign: "center",
          }}
        >
          <div style={{ fontSize: "13px", paddingBottom: "5px" }}>
            <br />
            <table
              style={{ width: "95%", margin: "0px auto" }}
              className="srftable"
            >
              <tr style={{ textAlign: "left" }}>
                <th
                  rowSpan={"2"}
                  style={{
                    width: "25%",
                    padding: "7px",
                    borderRight: "none",
                    borderBottom: "none",
                  }}
                >
                  Client
                </th>
                <td
                  rowSpan={"2"}
                  style={{
                    width: "25%",
                    padding: "7px",
                    borderRight: "none",
                    borderBottom: "none",
                  }}
                >
                  {companyName}
                </td>
                <th
                  style={{
                    padding: "7px",
                    borderRight: "none",
                    width: "25%",
                    borderBottom: "none",
                  }}
                >
                  WO-PO No.
                </th>
                <td
                  style={{
                    padding: "7px",
                    width: "25%",
                    borderBottom: "none",
                  }}
                >
                  {" "}
                  {srfsData?.poNumber}
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    padding: "7px",
                    borderRight: "none",
                    width: "25%",
                    borderBottom: "none",
                    textAlign: "left",
                  }}
                >
                  Date
                </th>
                <td
                  style={{
                    padding: "7px",
                    width: "25%",
                    borderBottom: "none",
                    textAlign: "left",
                  }}
                >
                  {moment(srfsData?.poDate).format("DD-MM-YYYY")}
                </td>
              </tr>
              <tr style={{ textAlign: "left" }}>
                <th
                  style={{
                    width: "25%",
                    padding: "7px",
                    borderBottom: "none",
                    borderRight: "none",
                  }}
                >
                  Address
                </th>
                <td
                  colSpan={"3"}
                  style={{
                    width: "25%",
                    padding: "7px",
                    borderBottom: "none",
                  }}
                >
                  {newAddressArray?.[0]?.address}
                </td>
              </tr>
              <tr style={{ textAlign: "left" }}>
                <th
                  style={{
                    padding: "7px",
                    borderBottom: "none",
                    borderRight: "none",
                    width: "25%",
                  }}
                >
                  GST No
                </th>
                <td
                  style={{
                    padding: "7px",
                    width: "25%",
                    borderBottom: "none",
                    borderRight: "none",
                  }}
                >
                  {clientArray[0]?.gstNumber}
                </td>
                <th
                  style={{
                    padding: "7px",
                    borderBottom: "none",
                    borderRight: "none",
                    width: "25%",
                  }}
                >
                  Customer Name
                </th>
                <td
                  style={{
                    padding: "7px",
                    width: "250px",
                    borderBottom: "none",
                    width: "25%",
                  }}
                >
                  {clientArray[0]?.contact
                    ? JSON.parse(clientArray[0]?.contact)[0]?.contactPersonName
                    : ""}
                </td>
              </tr>
              <tr style={{ textAlign: "left" }}>
                <th
                  style={{
                    padding: "7px",
                    width: "25%",
                    borderRight: "none",
                  }}
                >
                  Email
                </th>
                <td
                  style={{
                    padding: "7px",
                    width: "25%",
                    borderRight: "none",
                  }}
                >
                  {clientArray[0]?.contact
                    ? JSON.parse(clientArray[0]?.contact)[0]?.emailId
                    : ""}
                </td>
                <th
                  style={{
                    padding: "7px",
                    width: "25%",
                    borderRight: "none",
                  }}
                >
                  Phone Number
                </th>
                <td style={{ padding: "7px", width: "250px" }}>
                  {clientArray[0]?.contact
                    ? JSON.parse(clientArray[0]?.contact)[0]?.contact
                    : ""}
                </td>
              </tr>
            </table>
            <table
              style={{ width: "95%", margin: "10px auto" }}
              className="srftable"
            >
              <tr style={{ textAlign: "left" }}>
                <th
                  style={{
                    width: "25%",
                    padding: "7px",
                    borderBottom: "none",
                    borderRight: "none",
                  }}
                >
                  SRF Number
                </th>
                <td
                  style={{
                    width: "25%",
                    padding: "7px",
                    borderBottom: "none",
                    borderRight: "none",
                  }}
                >
                  {serviceReqNumber}
                </td>
                <th
                  style={{
                    padding: "7px",
                    borderBottom: "none",
                    borderRight: "none",
                    width: "25%",
                  }}
                >
                  Date of Receipt
                </th>
                <td
                  style={{
                    padding: "7px",
                    width: "25%",
                    borderBottom: "none",
                  }}
                >
                  {moment(receiptDate).format("DD-MM-YYYY")}
                </td>
              </tr>
              <tr style={{ textAlign: "left" }}>
                <th
                  style={{
                    padding: "7px",
                    borderBottom: "none",
                    borderRight: "none",
                    width: "25%",
                  }}
                >
                  DC Number
                </th>
                <td
                  style={{
                    padding: "7px",
                    width: "25%",
                    borderBottom: "none",
                    borderRight: "none",
                  }}
                >
                  {dcNumber}
                </td>
                <th
                  style={{
                    padding: "7px",
                    borderBottom: "none",
                    borderRight: "none",
                    width: "25%",
                  }}
                >
                  DC Date
                </th>
                <td
                  style={{
                    padding: "7px",
                    width: "250px",
                    borderBottom: "none",
                    width: "25%",
                  }}
                >
                  {moment(dcDate).format("DD-MM-YYYY")}
                </td>
              </tr>
              <tr style={{ textAlign: "left" }}>
                <th
                  style={{
                    padding: "7px",
                    width: "25%",
                    borderRight: "none",
                  }}
                >
                  Entry Date
                </th>
                <td
                  style={{
                    padding: "7px",
                    width: "25%",
                    borderRight: "none",
                  }}
                >
                  {moment(entryDate).format("DD-MM-YYYY")}
                </td>
                <th
                  style={{
                    padding: "7px",
                    width: "25%",
                    borderRight: "none",
                  }}
                >
                  Calibration to be Completed on
                </th>
                <td style={{ padding: "7px", width: "250px" }}>
                  {moment(commitedDate).format("DD-MM-YYYY")}
                </td>
              </tr>
            </table>
            <table
              style={{ width: "95%", margin: "0px auto", fontSize: "11px" }}
              className="srftable"
            >
              <thead>
                <tr>
                  <th
                    colSpan={11}
                    style={{ padding: "5px", borderBottom: "none" }}
                  >
                    INSTRUMENT DETAILS
                  </th>
                </tr>
                <tr>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    SI.No
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Description of the Equipment
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Make
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Model
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Serial Number
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Identification Number
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Range
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Calibration Points If any
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Declared Accuracy / Acceptance Criteria
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Requested Due Date
                  </th>
                  <th style={{ padding: "7px" }}>Condition of UUC / Remarks</th>
                </tr>
              </thead>
              {pushArray?.length > 0 ? (
                <tbody>
                  {pushArray?.map((item, index) => {
                    return (
                      <tr style={{ lineHeight: "13px" }} key={index} id="pagbreaksborder">
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {index + 1}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item?.instrumentId}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item?.make
                            ?.replaceAll("||", ", ")
                            ?.replaceAll("|", " to ")
                            ?.replaceAll("#", " ")}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item?.model}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item?.serialNumber}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item?.DUCID}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item?.ranges
                            ?.replaceAll("||", ", ")
                            ?.replaceAll("|", " to ")
                            ?.replaceAll("#", " ")}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item?.calPoint}{" "}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item?.accuracy
                            ? `± ${item?.accuracy
                                ?.replaceAll("||", ", ")
                                ?.replaceAll("|", " to ")
                                ?.replaceAll("#", " ")}`
                            : ""}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item?.calFrequency}
                        </td>
                        <td style={{ padding: "7px", borderTop: "none" }}>
                          {item?.ConOfDuc}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="11">No items added</td>
                  </tr>
                </tbody>
              )}
            </table>
            <Box
              sx={{
                width: "95%",
                margin: "10px auto",
                // border: "1px solid black",
                // padding: "5px",
                fontSize: "13px",
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
              }}
            >
              <table style={{ border: "none" }}>
                <tbody>
                  {CSRFformState?.map((item, index) => (
                    <tr key={index} style={{pageBreakInside:"avoid"}} id="pagbreaksborder">
                      {!item?.hasPoints && (
                        <td
                          style={{
                            width: "40%",
                            padding: "0px 5px",
                            border: "1px solid black",
                            borderBottom:"none",
                            borderRight:"none",
                            verticalAlign:"top",
                          }}
                        >
                          <Typography variant="span">
                            {index + 1}.{item?.question}
                          </Typography>
                        </td>
                      )}

                      {item?.hasPoints && (
                        <td
                          style={{
                            width: "40%",
                            padding: "0px 5px",
                            border: "1px solid black",
                            borderBottom:"none",
                            borderRight:"none",
                            verticalAlign:"top",
                          }}
                        >
                          <Typography variant="span">
                            <b>
                              {index + 1}.{item?.question}
                            </b>
                          </Typography>
                          {item?.points?.map((option, index) => (
                            <Typography
                              variant="caption"
                              display="block"
                              key={index}
                            >
                              {option}
                            </Typography>
                          ))}
                        </td>
                      )}
                      <td style={{ border: "1px solid black" ,borderBottom:"none", width:"60%" , verticalAlign:"top",padding:"0px 5px"}}>
                        {item?.options?.map((option, optionIndex) => (
                          <span key={optionIndex}>
                            <Checkbox
                              size="small"
                              sx={{ m: "0px", p: "0px 5px" }}
                              checked={item?.answer === option}
                            />
                            {option}
                          </span>
                        ))}
                      {item?.hasDetails && (
                        <div
                          style={{
                            border: "none",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft:"5px"
                          }}
                        >
                          <Typography variant="span">
                            {item?.details}
                          </Typography>
                        </div>
                      )}
                      {item?.hasoptions && (
                        <div
                          style={{
                            border: "none",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {item?.options1.map((option, optionIndex) => (
                            <span key={optionIndex}>
                              <Checkbox
                                size="small"
                                sx={{ m: "0px", p: "0px 5px" }}
                                checked={item?.details === option}
                              />
                              {option}
                            </span>
                          ))}
                        </div>
                      )}
                      {item?.hasPoints && (
                        <div
                          style={{
                            border: "none",
                            // display: "flex",
                            // alignItems: "center",
                          }}
                        >
                          <FormControl
                            variant="standard"
                            sx={{ minWidth: "100%" }}
                          >
                            <Typography variant="span">
                              Certificate to be issued in the name of:                            
                              {quest15?.details?.split(",")[1]?.trim()}
                            </Typography>
                          </FormControl>
                        </div>
                      )}
                      </td>

                    </tr>
                  ))}
                   <tr id="pagbreaksborder">
                    <td colSpan={2} style={{ border:"1px solid black",borderRight:"none",borderBottom:"none" }}>
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* <table
                style={{
                  border: "none",
                  margin: "2px 5px",
                  padding: "5px",
                }}
              >
                <tbody style={{ padding: "5px" }}>
                  <tr style={{ marginTop: "5px" }}>
                    <td
                      colSpan={2}
                      style={{
                        textAlign: "left",
                        border: "none",
                      }}
                    >
                      <Typography variant="span">
                        <span>16.Mode of Delivery & Collection Preference</span>
                      </Typography>
                    </td>
                    <td style={{ border: "none" }}>
                      <Stack direction="row" spacing={2}>
                        <input
                          type="checkbox"
                          id="drop_pickup"
                          checked={quest17?.drop_pickup}
                        />
                        <span>Drop & Pick-Up by Customer</span>
                      </Stack>
                    </td>
                    <td style={{ border: "none" }}>
                      <Stack direction="row" spacing={2}>
                        <input
                          type="checkbox"
                          id="pickup_by_external_provider"
                          checked={quest17?.pickup_by_external_provider}
                        />
                        <span>Pick-Up by Customer Designated External Provider</span>
                      </Stack>
                    </td>
                    <td style={{ border: "none" }}>
                      <Stack direction="row" spacing={2}>
                        <input
                          type="checkbox"
                          id="jrpm_send_by_courier"
                          checked={quest17?.jrpm_send_by_courier}
                        />
                        <span>Company to Send by Courier</span>
                      </Stack>
                    </td>
                  </tr>
                </tbody>
              </table> */}
            </Box>
          </div>
        </div>
        <div style={{ width: "100%" }} id="header-section"></div>
      </div>
      <ToastContainer />
      {downloadPdfloading && (
        <DownloadPdfLoaderModal loading={downloadPdfloading} name={"SRF"} />
      )}
    </Paper>
  );
}
